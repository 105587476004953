var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var module = {
  exports: exports
};

// A Javascript implementaion of the "xorshift7" algorithm by
// François Panneton and Pierre L'ecuyer:
// "On the Xorgshift Random Number Generators"
// http://saluc.engr.uconn.edu/refs/crypto/rng/panneton05onthexorshift.pdf
(function (global, module, define) {
  function XorGen(seed) {
    var me = this || _global; // Set up generator function.

    me.next = function () {
      // Update xor generator.
      var X = me.x,
          i = me.i,
          t,
          v,
          w;
      t = X[i];
      t ^= t >>> 7;
      v = t ^ t << 24;
      t = X[i + 1 & 7];
      v ^= t ^ t >>> 10;
      t = X[i + 3 & 7];
      v ^= t ^ t >>> 3;
      t = X[i + 4 & 7];
      v ^= t ^ t << 7;
      t = X[i + 7 & 7];
      t = t ^ t << 13;
      v ^= t ^ t << 9;
      X[i] = v;
      me.i = i + 1 & 7;
      return v;
    };

    function init(me, seed) {
      var j,
          w,
          X = [];

      if (seed === (seed | 0)) {
        // Seed state array using a 32-bit integer.
        w = X[0] = seed;
      } else {
        // Seed state using a string.
        seed = "" + seed;

        for (j = 0; j < seed.length; ++j) {
          X[j & 7] = X[j & 7] << 15 ^ seed.charCodeAt(j) + X[j + 1 & 7] << 13;
        }
      } // Enforce an array length of 8, not all zeroes.


      while (X.length < 8) X.push(0);

      for (j = 0; j < 8 && X[j] === 0; ++j);

      if (j == 8) w = X[7] = -1;else w = X[j];
      me.x = X;
      me.i = 0; // Discard an initial 256 values.

      for (j = 256; j > 0; --j) {
        me.next();
      }
    }

    init(me, seed);
  }

  function copy(f, t) {
    t.x = f.x.slice();
    t.i = f.i;
    return t;
  }

  function impl(seed, opts) {
    if (seed == null) seed = +new Date();

    var xg = new XorGen(seed),
        state = opts && opts.state,
        prng = function () {
      return (xg.next() >>> 0) / 4294967296;
    };

    prng.double = function () {
      do {
        var top = xg.next() >>> 11,
            bot = (xg.next() >>> 0) / 4294967296,
            result = (top + bot) / (1 << 21);
      } while (result === 0);

      return result;
    };

    prng.int32 = xg.next;
    prng.quick = prng;

    if (state) {
      if (state.x) copy(state, xg);

      prng.state = function () {
        return copy(xg, {});
      };
    }

    return prng;
  }

  if (module && module.exports) {
    module.exports = impl;
  } else if (define && define.amd) {
    define(function () {
      return impl;
    });
  } else {
    (this || _global).xorshift7 = impl;
  }
})(exports, module, // present in node.js
false // present with an AMD loader
);

export default module.exports;